<template>
  <div class="home">
    <div class="p-16 flex flex-col">
      <div class="flex flex-col w-full justify-center mb-10">
        <!-- <h2 class="text-md">Hello, {{$store.state.name}}</h2> -->
      </div>
      <!-- <div class="flex flex-col w-full justify-center mb-10">
        <label for="token">Token (Use this to access private notes)</label>
        <input type="password" v-model="token" id="search" class="border p-2">
      </div> -->
      <div class="flex flex-col w-full justify-center">
        <label for="note">Add Note</label>
        <textarea id="note" class="border w-full" v-model="note.text"></textarea>
        <button type="button" class="bg-black text-white"  @click="AddNote">Save</button>
        <div class="text-red flex flex-col">
          <p v-for="(error,i) in error_messages" :key="i">{{error}}</p>
        </div>
        <div v-if="loading.note" class="loading">Loading</div>
      </div>
      <div class="mt-10 flex flex-col w-full justify-center">
          <div class=" p-2 my-6 flex flex-wrap">
            <div class="w-full flex flex-col">
              <label for="search">Search Notes</label>
              <input type="text" v-model="search" id="search" class="border p-2">
            </div>
            <!-- <div class="w-4/12 justify-center items-end flex">
              <button class="bg-black text-white p-2 w-1/2" @click="Search">Search</button>
            </div> -->
            <div class="w-full">
              <div v-if="loading.search" class="loading">Loading</div>
            </div>
          </div>
          <div class=" p-2 my-6 flex flex-col">
            <h2 class="text-xl">Search Results</h2>
            <div v-for="(note,i) in search_results" :key="i" class=" p-2 my-6 flex border flex-col">
              <p>
                {{note.value}}
              </p>
              <p v-if="note.metadata">
                <small>{{note.metadata.name}} - {{getDate(note.metadata.created_at)}}</small>
              </p>
            </div>
          </div>
          <!-- <div class=" p-2 my-6 flex flex-col">
            <h2 class="text-xl">Notes</h2>
            <div v-for="(note,i) in notes" :key="i" class=" p-2 my-6 flex border">
                {{note}}
            </div>
          </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import {onMounted,ref} from 'vue'
import moment from 'moment'
export default {
  name: 'Home',
  data: () => {
    return {
      error_messages: [],
      loading: {
        search: false,
        note: false,
      },
      note: {
        text: '',
      },
      search_results: [],
      search: '',
      search_timeout: false,
      token: '',
    }
  },
  setup() {
    let notes = ref([])
    let api = ref('http://localhost:8787')
    if(window.location.hostname != 'localhost') api.value = 'https://notesandthings-api.originalbuilders.workers.dev'
    onMounted(async()=>{
      // let response = await fetch(`${api.value}/notes/`,{
      //   cache: 'no-cache',
      // })
      // notes.value = await response.json();
    })
    return {notes,api}
  },
  watch: {
    search(val) {
      if(this.search_timeout) clearTimeout(this.search_timeout)
      this.search_timeout = setTimeout(this.Search,500)
    },
  },
  methods: {
    getDate(timestamp) {
      return moment(timestamp).format('MMMM Do YYYY, h:mm a')
    },
    async Search() {
      this.loading.search = true
      let response = await fetch(`${this.api}/search/`,{
        method: "POST",
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(this.search),
      })
      this.search_results = await response.json()
      this.loading.search = false
    },
    async AddNote(){
      this.error_messages = []
      this.loading.note = true
      let response = await fetch(`${this.api}/note/`,{
        method: "POST",
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({note:this.note,name:this.$store.state.name}),
      })
      response = await response.json()
      if(!response || response.status == '0') {
        this.error_messages.push('Note was invalid')
      }
      this.loading.note = false
      this.note.text = ''
    },
  },
  components: {
  }
}
</script>
